import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { navigate, RouteComponentProps } from '@reach/router'
import logo from '@signatu/media/images/signatu_symbol_white.svg'
import check from '@signatu/media/images/icons/check.png'
import apikey from '@signatu/media/images/icons/apikey.png'
import controller from '@signatu/media/images/controller.png'
import generate from '@signatu/media/images/generate.png'
import share from '@signatu/media/images/icons/share.png'
import trace from '@signatu/media/images/trace.png'
import compliance from '@signatu/media/images/icons/authority.png'
import ai from '@signatu/media/images/trace.png'

import { useSignatuSelector } from '@signatu/models-redux'
import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import Card from '../components/content/card'
import Quote from '../components/content/quote'
import Tryit from '../components/signup/tryit-action'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import { Highlight } from '@signatu/common-react'
import * as ConsentReact from '@signatu/consent-react'
import { anonSubject } from '@signatu/consent'

import PublicLayout from '../layouts/public-layout'

const videoStyle = {
    marginTop: '64px',
    width: '75%',
    borderColor: '#0b3297',
    borderWidth: '4px',
    borderStyle: 'double',
}

/** Common GraphQL queries */
export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(width: 200, layout: CONSTRAINED)
        }
        publicURL
    }
`

export const fixedImageSmall = graphql`
    fragment fixedImageSmall on File {
        childImageSharp {
            gatsbyImageData(width: 200)
        }
        publicURL
    }
`

export const pageQuery = graphql`
    query {
        logos: allFile(filter: { absolutePath: { regex: "//images/partners/.*/" } }) {
            nodes {
                ...fixedImageSmall
            }
        }
    }
`

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(),
            },
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(4),
            },
            alignSelf: 'center',
        },
        partnerImages: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(8),
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        partnerImage: {
            maxWidth: 200,
            margin: theme.spacing(2),
        },
    })
)

interface HomeComponentProps extends RouteComponentProps {}

const Spacer = () => <Box sx={{ height: '32px' }} />

const shouldRedirect = ({ location, isLoggedIn }: { location?: Location; isLoggedIn: boolean }) => {
    if (!location) {
        return false
    }

    return isLoggedIn && !location.pathname.startsWith('/app')
}

const HomeComponent: React.FC<HomeComponentProps> = (props) => {
    const classes = useStyles(props)
    const { location } = props
    const isLoggedIn = useSignatuSelector((state) => state.account.isLoggedIn)

    useEffect(() => {
        if (shouldRedirect({ location, isLoggedIn })) {
            navigate('/app/')
        }
    })
    /** If logged in, return null and wait for redirect (above). We cannot induce a state change here */
    if (shouldRedirect({ location, isLoggedIn })) {
        return null
    }

    return (
        <PublicLayout contentPage={false}>
            {/* LeadBooster does not play nice with React hooks yet, because it bundles React */}
            {/* <LeadBooster {...config.leadbooster} /> */}

            <Card color="primary" image={logo} imageType="static" title="Benefit from Using AI & Data Responsibly">
                <Typography variant="h6" color="secondary">
                    Streamline data use processes to achieve your business strategy aims.
                    <Spacer />
                    Make better decisions and innovative processes, products and services through increased use of AI &
                    data and responsible usage.
                </Typography>
            </Card>

            <Card>
                <Typography variant="h6" color="primary">
                    Using a world-leading semantic Data Privacy Vocabulary and AI, Signatu's AI-driven automation
                    captures your company's data processing and security knowledge and streamlines your data use
                    processes with your business strategy aims, across all domains, in a central register, automatically
                    generating all your data compliance documents.
                </Typography>
            </Card>

            <Card
                image={generate}
                imageType="static"
                title="Standardize data processing and security knowledge"
                // link="product/trackerdetect/"
            >
                Signatu maps your compliance information from your compliance documents or from your compliance vendor
                (e.g. Excel) to a standardized semantic Data Privacy Vocabulary, and into a central and cloud based
                register, which automatically generates all your data compliance documents. Signatu solves the critical
                need for standardization and efficiency in data protection and security documentation.
            </Card>

            <Card image={check} imageType="static" imagePos="right" title="Key Benefits">
                <ul>
                    <li>Increases employee satisfaction with streamlined business processes</li>
                    <li>Enhances focus on your company's strategic initiatives</li>
                    <li>Enables organizations to handle complex, evolving privacy regulations at scale</li>
                    <li>Ensures accurate and efficient privacy management across large, diverse datasets</li>
                    <li>Reduces compliance-related tasks through AI automation</li>
                    <li>Reduces compliance-related risks</li>
                    <li>Reduces manual compliance work, freeing up valuable resources</li>
                </ul>
            </Card>

            <Card
                image={controller}
                imageType="static"
                imagePos="right"
                // link="product/policy/"
                title="Real-World Business Impact"
            >
                By automatically capturing and processing your company's data and security practices using a
                world-leading semantic Data Privacy Vocabulary, Signatu streamlines compliance processes to align with
                your strategic business goals. Our central register becomes a single source of truth, enabling:
                <ul>
                    <li>Automatic generation of all required compliance documents</li>
                    <li>Common understanding of data protection and security information</li>
                    <li>Consistent, up-to-date privacy practices across your organization</li>
                    <li>Rapid adaptation to new regulatory requirements</li>
                </ul>
            </Card>

            <Card image={share} imageType="static" /* link="collaborate/"*/ title="Return on Investment">
                <ul>
                    <li>Significant cost savings and productivity gains through automated compliance processes</li>
                    <li>Enhanced collaboration between business units and Privacy Teams</li>
                    <li>Creation of a trusting working environment leading to better employee engagement</li>
                    <li>Scalable privacy management that grows with your business</li>
                </ul>
            </Card>

            <Card
                title="AI powered by world-leading semantic Data Privacy Vocabulary"
                imageType="static"
                image={ai}
                imagePos="right"
            >
                In this demonstration, you'll see Signatu's AI in action, utilizing the world-leading semantic Data
                Privacy Vocabulary to describe data processing for B2C marketing purposes. Watch as our system precisely
                outlines the collection, use, and protection of customer data in a marketing context. While this example
                focuses on B2C marketing, remember – Signatu's power extends far beyond, supporting any domain where
                data protection and security compliance matter.
            </Card>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <video style={videoStyle} controls>
                    <source src="https://cdn.signatu.com/videos/2024-07-04-create-group.mov" />
                    Your browser does not support the video tag.
                </video>
            </Box>

            <Card title="Compliance Documentation" image={compliance} imageType="static">
                This video showcases Signatu's AI generating a Records of Processing Activities (RoPA) document, a
                crucial requirement for most organizations under data protection regulations like GDPR. Witness how our
                Data Privacy ontology-powered platform swiftly creates a comprehensive, compliant RoPA, dramatically
                reducing manual effort. While this demonstration focuses on RoPA generation, it illustrates the broader
                applications of our platform across all areas of data protection and security compliance.
            </Card>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <video controls style={videoStyle}>
                    <source src="https://cdn.signatu.com/videos/2024-07-04-create-ropa.mov" />
                    Your browser does not support the video tag.
                </video>
            </Box>

            <Card image={apikey} imageType="static" title="Developer Friendly">
                We aim to make integrating with Signatu from your website or app as easy as possible. Signatu provides a
                REST API, re-usable and composable React components, and a Javascript SDK.
            </Card>
            <Highlight
                language="jsx"
                live={true}
                scope={{
                    anonSubject,
                    ...ConsentReact,
                }}
            >
                {`
<ConsentButtons consentTarget={{subject: anonSubject(), target: "signatu.com/demo/home"}}>
 Using this React component looks easy! When your users accept or refuse a request like this, Signatu will safeguard the event for you. Go ahead, try to edit the black box, it's a live component. 
</ConsentButtons>
    `}
            </Highlight>
            {/* <br />
            <br />
            <Card image="eu-flag.png" imagePos="right" title="GDPR &amp; ePrivacy">
                The secure Signatu platform takes care of a lot of complex compliance details so that you don't have to.
                Our legal expert network reviews Privacy Policy clauses and Consent dialogs in sync with EU legal
                requirements.
            </Card> */}

            <Tryit />

            <Card paper={true} color="primary">
                <Quote from="Petter Gustafson, CTO, NHST Media Group">
                    Signatu has an innovative approach to enable publishers, and other businesses with web sites, to
                    manage users privacy rights when using the web sites. We are very happy with the results of the
                    collaboration so far.
                </Quote>
            </Card>
        </PublicLayout>
    )
}
export default HomeComponent
